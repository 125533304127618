<template>
	<div id="table">
		<el-input v-focus="focusState"
		    @keyup.enter.native="updateEnter()"
		    v-model="labelUpdateInfo"
			@blur="onUpdateBlurInfo('codeItems','codeItem')">
			<el-button v-if="showcancle" @mousedown.native="cancleUpdateInfoClick()" slot="append" class="elInput-circle-close" icon="el-icon-circle-close"></el-button>
		</el-input>
  </div>
</template>

<script>
export default {
  props: {
    showcancle: Boolean
  },
  data () {
    return {
      focusState: false,
      labelUpdateInfo: '',
      cancleUpdate: false
    }
  },
  directives: {
    focus: {
      update: function (el, { value }) {
        	if (value) {
        		el.children[0].focus()
        	}
      }
    }
  },
  mounted () {
  },
  methods: {
    // 区分编辑和添加（编辑用到此函数，传来了需要编辑的名称）
    updateStandard (name) {
      console.log(name)
      var _this = this
      _this.labelUpdateInfo = name
      setTimeout(() => {
        _this.focusState = true
      }, 1)
    },
    updateEnter () {
      this.focusState = false
    },
    // 输入框失去焦点事件
    onUpdateBlurInfo (List, item) {
      // 如果点击了取消按钮，则不执行请求等操作，只隐藏输入框
      if (this.cancleUpdate) {
        this.labelUpdateInfo = ''
        this.focusState = false
        this.cancleUpdate = false
        this.$emit('showInputFunc', false)
      } else {
        this.updateInfo(List, item)
      }
    },
    cancleUpdateInfoClick () {
      this.cancleUpdate = true
    },
    updateInfo (List, item) {
      var label = this.labelUpdateInfo
      this.$message({
		    type: 'success',
		    message: '修改成功'
      })
      this.labelUpdateInfo = ''
      this.focusState = false
      this.$emit('showInputFunc', false) // 传过去用于输入框的显隐
      this.$emit('inputValue', label) // 传过去输入框的值
    }
  }
}
</script>

<style lang="less" scoped>
</style>
