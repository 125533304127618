<template>
  <div class="table-box">

    <div class="table-container">
    <el-row :gutter="24">
      <el-col :span="24">
        <el-row class="floatLeft">
          <el-input v-model="searchValue" style="width:200px;" placeholder="请输入角色名称"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          <el-button type="primary" icon="el-icon-search" @click="show()" v-show="false">show</el-button>
          <inputBlur v-show="showInput" :showcancle="true" ref="myInputBlur" @showInputFunc="showInputValue" @inputValue="submitValue"></inputBlur>
        </el-row>
        <el-row class="float">
          <el-button type="primary" icon="el-icon-plus" @click="addOrg()" v-if="action.addorganization">添加</el-button>
        </el-row>
        <el-table
          id="out-table"
          ref="singleTable"
          stripe
          :data="organizList"
          highlight-current-row
          style="width:100%;"
          border
        >
          <el-table-column align="left" label="组织名称">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column align="left" label="创建时间">
            <template slot-scope="scope">{{ scope.row.creationTimeStr }}</template>
          </el-table-column>
          <el-table-column label="操作" v-if="action.updateorganization||action.deleteorganization">
            <template slot-scope="scope">
              <i class="el-icon-edit margin" @click="editOrg(scope.row)" v-if="action.updateorganization"></i>
              <i class="el-icon-delete margin" @click="deletOrg(scope.$index,organizList,scope.row)" v-if="action.deleteorganization" ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    </div>
    <el-dialog
      :title="addOrEdit=='add'?'添加组织':'编辑组织'"
      :visible.sync="showDialog"
      width="30%"
      center>
      <div class="addOrg"><p>组织名称：</p><el-input v-model="orgInputValue"></el-input></div>
      <div class="addOrgBtn">
        <el-button type="danger" @click="cancel()">取消</el-button>
        <el-button type="primary" @click="submit()">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import inputBlur from '@/components/excel/inputBlur.vue'
export default {
  name: 'User',
  data () {
    return {
      showInput: false,
      organizList: [],
      organizListTemp: [],
      searchValue: '',
      addOrEdit: 'add',
      showDialog: false,
      orgInputValue: '',
      editOrgId: ''
    }
  },
  components: {
    inputBlur
  },
  created () {
    this.getUsers()
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    })
  },
  methods: {
    show () {
      this.showInput = true
      this.$refs.myInputBlur.updateStandard('')
    },
    showInputValue (i) {
      this.showInput = i
    },
    submitValue (value) {
      // console.log(value)
    },

    getUsers () {
      this.$axios.get('/api/Organization/GetOrganizList').then(res => {
        this.organizList = res
        this.organizListTemp = res
      })
    },
    search () {
      var str = this.searchValue
      // console.log(this.organizListTemp)
      if (str == '') {
        this.organizList = this.organizListTemp
        return
      }
      this.organizList = this.organizListTemp.filter(n => {
        return n.name.indexOf(str) > -1
      })
    },
    addOrg () {
      this.addOrEdit = 'add'
      this.showDialog = true
      this.orgInputValue = ''
    },
    cancel () {
      this.showDialog = false
    },
    submit () {
      if (this.orgInputValue) {
        var id = this.editOrgId
        var url = this.addOrEdit == 'add' ? '/api/Organization/AddOrganiz?' : '/api/Organization/UpdateOrganiz?OrganizId=' + this.editOrgId + '&'
        this.$axios.get(url + 'OrganizName=' + this.orgInputValue).then(res => {
          if (this.addOrEdit == 'add') {
            var org = {}
            org.id = res.id
            org.name = this.orgInputValue
            org.creationTimeStr = this.getFullTime()
            this.organizListTemp.push(org)
          } else {
            var index = this.organizListTemp.findIndex(n => n.id == id)
            this.organizListTemp[index].name = this.orgInputValue
          }
          this.$message({
            type: 'success',
            message: this.addOrEdit == 'add' ? '添加成功' : '修改成功'
          })
        })
        this.showDialog = false
      } else {
        this.$message({
          type: 'warning',
          message: '请输入组织名称'
        })
      }
    },
    editOrg (row) {
      this.addOrEdit = 'edit'
      this.showDialog = true
      this.editOrgId = row.id
      this.orgInputValue = row.name
    },
    deletOrg (index, rows, row) {
      // console.log(row)
      this.$confirm('此操作将删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Organization/DeleteOrganiz?OrganizId=' + row.id).then(res => {
          rows.splice(index, 1)
          this.$message({
            type: 'success',
            message: '组织移除项目成功!'
          })
        })
      })
    },
    getFullTime () {
      let date = new Date(); let // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      // console.log()
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    }
  }
}
</script>

<style scoped lang="less">
.float {
  float: right;
  margin-bottom: 20px;
}
.float > button {
  margin-left: 10px;
  background: #0094CE;
  border: none;
}
.floatLeft {
  float: left;
  margin-bottom: 20px;
}
.floatLeft > button {
  margin-left: 10px;
  background: #475065;
  border: none;
}
	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		display: flex;
		height: calc(100vh - 170px);
		flex-wrap: wrap;
		overflow-y: auto;
		align-content:flex-start;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}
  .margin {
    margin-right: 10px;
  }
  .addOrg {
    display: flex;
    p {
      margin-top: 13px;
      margin-right: 10px;
    }
    .el-input{
      width: 80%;
    }
  }
  .addOrgBtn {
    margin-left: 69%;
    margin-top: 20px;
  }
</style>
